@import "webfonts";

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

html, body {
	height: 100%;
	padding: 0px;
	margin: 0px;
}

body, p, .box h3 {
	font-family: 'Open Sans';
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: auto;
}

h1, h2 {
	font-family: "Furore", Arial;
	letter-spacing: 3px;
}

h1 {
	line-height: 1.4em;
}

h2 {
	line-height: 1.2em;
}

h3 {
	font-family: 'Open Sans';
	font-weight: 300;
	font-size: 20px;
}

a, a:link, a:active, a:visited {
	color: #2245c9;
	text-decoration: none;
}

a:hover {
	color: #3d5ac9;
}

.btn {
	display: inline-block;
	border: 1px solid #2245c9;
	border-radius: 1px;
	padding: 10px;
	min-width: 120px;
	text-align: center;
	font-weight: bold;
}

.btn:hover {
	color: #111;
	background-color: #2245c9;
}

.sponsors,
.events {
	list-style: none;
	margin: 0px;
	padding: 0px;
	overflow: hidden;
}

.sponsors {
	.sponsor {
		margin: 0px 0px 20px 0px;

		.box {
			position: relative;
			border: 1px solid #eee;
			background-color: #fff;
			color: #000;
			padding: 10px 10px 20px 10px;

			-webkit-box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.41);
			-moz-box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.41);
			box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.41);

			.links {
				overflow: hidden;

				.social {
					float: left;
				}
			}

			h3 {
				height: 100px;
			}

			img {
				display: block;
				width: auto;
				height: auto;
				max-width: 200px;
				margin: 0 auto;
			}
		}
	}
}

.row {
	margin: 0px -7.5px;

	.cell {
		padding: 0px 7.5px 0px 7.5px;
		position: relative;
		float: left;
	}
}

@media screen and (min-width: 480px) {
	.sponsors {
		.sponsor {
		}
	}
}

@media screen and (min-width: 768px) {
	.row {
		.cell {
			width: 50%;
		}
	}


	.sponsors {
		.sponsor {

			/*&:nth-child(2) {
				margin-right: 0px;
			}*/

			.description {
				min-height: 170px;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.row {
		.cell {
			width: 33%;
		}
	}

	.sponsors {
		.sponsor {
			/*&:nth-child(3) {
				margin-right: 0px;
			}*/
		}
	}
}

/*
.events {
	margin: 50px 0px 0px 0px;
}

.events > li {
	width: 50%;
	padding: 10px;
}

.events .event {
	padding: 0px;
}

.events .event .image-wrapper {
	background: #000;
	position: relative;
}

.events .event .image-wrapper img {
	opacity: 0.2;
	display: block;
	width: auto;
	max-width: 100%;
	height: auto;
}

.events .event .image-wrapper h3 {
	position: absolute;
	left: 10px;
	bottom: 10px;
	font-weight: bold;
}

.events .event .description {
	padding: 0px 20px 20px 20px;
}
*/

.content-box {
    box-shadow: 0px 0px 3px rgba(0,0,0,0.8);
    background-color: #181818;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.social {
	overflow: hidden;
}

.social span {
	display: block;
	float: left;
	line-height: 24px;
}

.social .ionicon {
	font-size: 24px;
	margin-right: 10px;
	width: 24px;
	text-align: center;
}
/*
.page-outer {
}

.fullpage-wrap {
	display: table;
    width: 100%;
    height: 100%;
    min-height: 100%;
	background: url(images/bg.jpg) no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.fullpage-wrap.dark {
	background-color: #111;
	color: #fff;
}

.fullpage-wrap.light {
	background-color: #fff;
	color: #111;

*/

.fullpage-wrap {
	position: relative;
	display: block;
	width: 100%;
	min-height: 100vh;

	background: url("/assets/images/darknoise.png") no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.fullpage-wrap.dark {
	background-color: #111;
	color: #fff;
}

.fullpage-wrap.light {
	background-color: #fff;
	color: #111;
}

.fullpage-wrap .inner {
}

.fullpage-wrap .inner .content {
	margin-right: auto;
	margin-left: auto;
	width: 90%;
	z-index: 100;
	overflow: hidden;
	padding: 60px 0px;
}

@media screen and (min-width: 1200px) {
	.fullpage-wrap {
		.inner {
			.content {
				width: 980px;
			}
		}
	}
}


.fullpage-video .inner {
	z-index: 2;
	background: rgba(0,0,0,0.92);
}

.fullpage-wrap.center .inner {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.fullpage-wrap.center .inner .content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	padding: 0px;
}

.divider {
	display: block;
	width: 100%;
	clear: both;
}

nav, .footer {
	background-color: #222;
	z-index: 1000;
}

nav ul {
	list-style: none;
	margin: 0px 20px 0px 0px;
	padding: 0px;
}

.logo {
	display: block;
	width: auto;
	padding: 10px;
	float: left;
	margin-right: 20px;
}

.logo img {
	width: auto;
	height: 30px;
}

.logo-large img {
	width: 100%;
	max-width: 920px; 
	height: auto;
	margin: 0px auto;
}

.logo-large span {
	display: none;
}

.next-page {
	font-size: 32px;
	position: absolute;
	bottom: 60px;
	left: 50%;
	min-width: 260px;

	padding: 0px 0px 0px 48px;

	z-index: 100;
	color: #fff;

	transform: translate(-50%,0px);
	
	span {
		display: block;
		font-size: 48px;
		position: absolute;
		top: -10px;
		left: 0px;
	}
}

.nav-main {
	float: left;
	width: calc(100% - 350px);
}

.nav-buttons {
	float: right;
}

.nav-buttons a span {
	font-size: 22px;
	line-height: 30px;
}

nav ul li {
	display: block;
	float: left;
	padding: 10px;
	line-height: 30px;
}

nav ul li a,
nav ul li a:link,
nav ul li a:visited,
nav ul li a:active {
	color: #fff;
	text-decoration: none;
}

nav.fixed {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 50px;
}

.footer {
	text-align: center;
	padding: 20px 0px 20px 0px;
}

.footer p {
	color: #fff;
	padding: 0px;
	margin: 0px;
}

.footer .footer-inner {
	margin-right: auto;
	margin-left: auto;
	//width: 980px;
}

.single-page .fullpage-wrap .content {
	padding-top: 60px;
}


.tv {
	display: none;
}

@media screen and (min-width: 768px) {
	.tv {
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 1;
		display: block;

		width: 100%;
		height: 100vh;

		overflow: hidden;
	}
	  
	.tv .screen {
		position: absolute;
		top: -20%;
		left: -20%;
		width: 140% !important;
		height: 140% !important;

		z-index: 1;
		margin: auto;

		opacity: 0;
		transition: opacity .5s;
	}

	.tv .screen.active {
		opacity: 1;
	}
}

iframe {
	margin: 0px 0px 20px 0px;
}

@media screen and (min-width: 768px) {
	iframe {
		margin: 0px 20px 0px 0px;
	}
}


/** user panel **/
.user-panel {
	float: right;
	width: 200px;
	position: relative;

	& > a {

		img {
			float: left;
			max-height: 100%;
			width: auto;
			height: auto;

			height: 30px;
			border-radius: 15px;
		}

		span {
			float: left;
			margin-left: 10px;
		}
	}

	ul {
		clear: both;
		display: none;
		position: absolute;
		top: 50px;
		left: 0px;
		width: 200px;
	background-color: #222;

	}

	&:hover {
		ul {
			display: block;

			li {
				display: block;
				float: none;
				line-height: 14px;
			
				&.spacer {
					border-bottom: 1px solid #111;
					line-height: 0px;
					height: 0px;
					padding: 0px;
					margin: 0px;
				}
			}
		}
	}
}