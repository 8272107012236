@font-face {
    font-family: 'Furore';
    src: url('/assets/fonts/furore-webfont.eot');
    src: url('/assets/fonts/furore-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/furore-webfont.woff2') format('woff2'),
         url('/assets/fonts/furore-webfont.woff') format('woff'),
         url('/assets/fonts/furore-webfont.ttf') format('truetype'),
         url('/assets/fonts/furore-webfont.svg#furoreregular') format('svg');
    font-weight: normal;
    font-style: normal;

}